import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography } from "@mui/material";
import { IMAGE_STORE } from '../assets';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import lottie from 'lottie-web';


const ComingSoon = () => {



    const statelite = useRef(null);


    useEffect(() => {
        const instance = lottie.loadAnimation({
            container: statelite.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: require('../assets/coming.json')
        })
        return () => instance.destroy();
    }, [])
    const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

    useEffect(() => {
        const targetDate = new Date();
        targetDate.setDate(targetDate.getDate() + 13);

        const timer = setInterval(() => {
            const now = new Date();
            const difference = targetDate - now;

            if (difference <= 0) {
                clearInterval(timer);
                setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
            } else {
                const days = Math.floor(difference / (1000 * 60 * 60 * 24));
                const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((difference % (1000 * 60)) / 1000);

                setTimeLeft({ days, hours, minutes, seconds });
            }
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    return (
        <Box sx={{ height: "100vh", px: { xs: 1, md: 2 }, textAlign: { xs: 'left', md: 'left' } }}>
            <Box sx={{ p: 2 }}>
                <img src={IMAGE_STORE.logo} alt='prime books' style={{ width: 120, maxWidth: '100%' }} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: 'center', mt: { xs: 7, md: 2 } }}>
                <Typography variant="h3" fontWeight={500} sx={{ fontSize: { xs: '1.5rem', md: '2rem' }, textAlign: 'center' }}>
                    Prime Publishing House
                </Typography>
                <Typography variant="body1" color='text.secondary' sx={{ fontSize: { xs: '0.9rem', md: '1rem' } }}>
                    Publishing | Library Supplies | Wholesale Distribution
                </Typography>
                <Typography variant="h4" fontWeight={500} sx={{ fontSize: { xs: '1.2rem', md: '1.5rem' }, pt: { xs: 1, md: 2 } }}>
                    We're coming soon.
                </Typography>
                <Typography variant="body1" fontWeight={400} mt={1} sx={{ fontSize: { xs: '0.85rem', md: '1rem' }, textAlign: 'center' }}>
                    Our Website is under construction. We'll be here soon with our new awesome site,<br />
                    Subscribe to be notified.
                </Typography>
                <Box mt={3} mb={3}>
                    <Typography variant="body1" fontWeight={500} fontSize={18} sx={{ fontSize: { xs: '1rem', md: '1.2rem' } }}>
                        {` ${timeLeft.days} days | ${timeLeft.hours} hours | ${timeLeft.minutes} minutes | ${timeLeft.seconds} seconds`}
                    </Typography>
                </Box>
                <Typography variant='body1' sx={{ fontSize: { xs: '0.85rem', md: '1rem' } }}>
                    Swatantrya Chowk, Mohite Complex Basement, Jalgaon - 425001
                </Typography>
                <Typography variant='body1' sx={{ fontSize: { xs: '0.85rem', md: '1rem' } }}>
                    96656 26717, 89992 34556
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 1 }}>
                    <MailOutlinedIcon fontSize='small' sx={{ mr: 1 }} />
                    <Typography sx={{ fontSize: { xs: '0.85rem', md: '1rem' } }}>
                        <a href="mailto:primepublishinghouse@gmail.com" style={{ textDecoration: 'none' }}>
                            primepublishinghouse@gmail.com
                        </a>
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', px: 3, mt: { xs: 11, md: 0 } }}>
                <Box
                    ref={statelite}
                    sx={{ width: 105, height: 105 }}
                />
            </Box>
        </Box >
    );
};

export default ComingSoon;
