import React, { useState } from 'react';
import { Box, Button, Drawer } from '@mui/material';
import { mkConfig, generateCsv, download } from "export-to-csv";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded';
import Papa from 'papaparse';  // Import PapaParse

const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: '.',
    useKeysAsHeaders: true,
});

const CustomTable = ({ data, columns, buttonTitle, AddComponent, buttonIcon }) => {
    const [open, setOpen] = useState(false);
    const [tableData, setTableData] = useState(data); // State for table data

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const handleExportRows = (rows) => {
        const rowData = rows.map((row) => row.original);
        const csv = generateCsv(csvConfig)(rowData);
        download(csvConfig)(csv);
    };

    const handleExportData = () => {
        const csv = generateCsv(csvConfig)(tableData);
        download(csvConfig)(csv);
    };

    const handleImportCsv = (event) => {
        const file = event.target.files[0];

        if (file) {
            Papa.parse(file, {
                header: true,
                complete: (result) => {
                    const parsedData = result.data;
                    setTableData(parsedData);  // Update table data with parsed CSV
                },
                error: (error) => {
                    console.error('Error parsing CSV:', error);
                },
            });
        }
    };

    const table = useMaterialReactTable({
        columns,
        data: tableData, // Use the tableData state for table content
        enableRowSelection: true,
        positionToolbarAlertBanner: 'bottom',
        enableFullScreenToggle: false,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnVisibilityToggle: false,
        enableDensityToggle: false,
        defaultDisplayColumn: {
            minSize: 40,
        },
        initialState: { density: 'compact', showGlobalFilter: true },
        muiTableBodyRowProps: ({ row }) => ({
            sx: {
                backgroundColor: row.original.isApproved === true ? '#423b31' : 'inherit',
                borderColor: '#ffa726',
                cursor: "pointer",

            },
        }),
        renderTopToolbarCustomActions: ({ table }) => (
            <Box
                sx={{
                    display: 'flex',
                    gap: '16px',
                    padding: '8px',
                    flexWrap: 'wrap',
                    mb: 1,
                }}
            >
                <Button
                    startIcon={<CloudUploadRoundedIcon />}
                    component="label"  // Use label to trigger file input
                >
                    Import Data
                    <input
                        type="file"
                        accept=".csv"
                        hidden
                        onChange={handleImportCsv}  // Handle CSV import
                    />
                </Button>
                <Button
                    onClick={handleExportData}
                    startIcon={<FileDownloadIcon />}
                >
                    Export All Data
                </Button>
                <Button
                    disabled={
                        !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                    }
                    onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                    startIcon={<FileDownloadIcon />}
                >
                    Export Selected Rows
                </Button>
            </Box>
        ),
    });

    const handleCloseDrawer = () => {
        setOpen(false);
    };

    return (
        <Box>
            <MaterialReactTable table={table} />
            <Drawer
                open={open}
                onClose={toggleDrawer(false)}
                anchor='right'
                elevation={5}
                transitionDuration={{ enter: 225, exit: 195 }}
                sx={{
                    '& .MuiDrawer-paper': {
                        borderTopLeftRadius: 10,
                        borderBottomLeftRadius: 10,
                    },
                }}
            >
                <Box sx={{ width: 1100, height: "100%", bgcolor: "#282828" }}>
                    {React.cloneElement(AddComponent, { handleCloseDrawer: handleCloseDrawer })}
                </Box>
            </Drawer>
        </Box>
    );
};

export default CustomTable;
