import React from 'react'
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { Box, Button, Drawer, Typography, LinearProgress } from "@mui/material"

const DeleteModal = ({ open, onClose, onConfirm, itemName }) => {
    return (
        <Drawer open={open} onClose={onClose} anchor='top'>
            <Box sx={{ height: 80, display: "flex", flexDirection: "row", justifyContent: 'space-around', alignItems: "center", bgcolor: "#241615", p: 2 }}>
                <Typography fontSize={17} color="white">
                    Are you sure you want to delete {itemName} ?
                </Typography>
                <Box>
                    <Button color='info' sx={{ mr: 2 }} onClick={onClose}>Cancel</Button>
                    <Button
                        variant='contained'
                        color='error'
                        startIcon={<DeleteRoundedIcon />}
                        onClick={onConfirm}
                    >
                        Delete
                    </Button>
                </Box>
            </Box>
            <LinearProgress color="error" />
        </Drawer>
    )
}

export default DeleteModal