import axios from "axios";

axios.defaults.withCredentials = true;
const BASE_URL = `http://localhost:5000/api/`;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

export const userLogin = async (data) => {
  try {
    const response = await axiosInstance.post("login", data);
    localStorage.setItem("userData", JSON.stringify(response.data));
    return response.data;
  } catch (error) {
    console.error("login error", error.response || error.message);
    throw new Error(error.response?.data?.message || error.message);
  }
};

export const userLogout = async () => {
  try {
    const response = await axiosInstance.post("logout");
    return response.data;
  } catch (error) {
    console.error("Logout error:", error.response || error.message);
    throw new Error(error.response?.data?.message || error.message);
  }
};

// ************* Book Api ***************

export const fetchBook = async () => {
  try {
    const response = await axiosInstance.get("books");
    return response.data;
  } catch (error) {
    console.log("fetch all book error", error.message);
    throw new Error(error.response?.data?.message || error.message);
  }
};

export const createBook = async (data) => {
  try {
    const response = await axiosInstance.post("addbook", data);
    return response.data;
  } catch (error) {
    console.log("add book erroor", error.response || error.message);
    throw new Error(error.response?.data?.message || error.message);
  }
};

export const deleteBook = async (id) => {
  try {
    const response = await axiosInstance.delete(`deletebook/${id}`);
    return response.data;
  } catch (error) {
    console.error("delete book error:", error.response || error.message);
    throw new Error(error.response?.data?.message || error.message);
  }
};
