import React from 'react';
import { Box, Typography, IconButton, Tooltip, } from '@mui/material';
import CustomTable from '../../components/CustomTable';
import EastIcon from '@mui/icons-material/East';


const data = [
    { _id: "50959844090", name: "harshal mahajan" },
    { _id: "50959844090", name: "harshal mahajan" },
    { _id: "50959844090", name: "harshal mahajan" },
    { _id: "50959844090", name: "harshal mahajan" },
    { _id: "50959844090", name: "harshal mahajan" },
]

const Users = () => {


    const columns = [
        { header: 'Sr.No', accessorFn: (row, index) => index + 1, grow: false, size: 50 },
        { header: 'ID', accessorKey: "_id" },
        { header: 'Name', accessorKey: "name" },
        {
            header: 'Actions',

            accessorFn: (row) => row,
            Cell: ({ cell }) => (
                <Box>

                    <Tooltip title="Details">
                        <IconButton color='secondary'>
                            <EastIcon fontSize='small' />
                        </IconButton>
                    </Tooltip>
                </Box>
            )



        }
    ]


    return (
        <Box sx={{ px: 2, pt: 1 }}>
            <Typography variant="h6" sx={{ fontSize: 18, fontWeight: 450, mb: 2 }}>Users</Typography>
            <CustomTable
                data={data}
                columns={columns}
                AddComponent={<></>}
            />
        </Box>
    );
};

export default Users;
