import { createSlice } from "@reduxjs/toolkit";



const initialState = {
    userData: {},
    isRegistered: false,
    inLoggedin: false
}

const UserSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        logout: (state) => {
            state.userData = {};
            state.isLogged = false;
        },
        register: (state, action) => {
            state.userData = action.payload;
            state.isRegistered = true;
            state.isLoggedIn = true;
        },
        login: (state, action) => {
            state.userData = action.payload
            state.inLoggedin = true
        },
        clearUserData: (state) => {
            state.userData = {};
            state.isLoggedIn = false;
        }
    }
})

export const { login, logout, register, clearUserData } = UserSlice.actions
export default UserSlice.reducer;