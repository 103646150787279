import React from 'react';
import { Box, Typography, Button, Card, Grid2, IconButton } from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

const categories = [
    { id: 1, name: 'Art & Photography', color: '#faf1ff' },
    { id: 2, name: 'Food & Drink', color: '#faf4eb' },
    { id: 3, name: 'Romance', color: '#f4e6e5' },
    { id: 4, name: 'Health', color: '#e6f2f4' },
];

const bestbooks = [
    { img: 'https://html.madrasthemes.com/bookworm-html/redesigned-octo-fiesta/assets/img/300x452/img1.jpg', name: 'think link a monk train Your Mind for peace and Purpose', author: 'Jay Shetty', price: '120' },
    { img: 'https://html.madrasthemes.com/bookworm-html/redesigned-octo-fiesta/assets/img/300x452/img1.jpg', name: 'think link a monk train Your Mind for peace and Purpose', author: 'Kelly Harms', price: '160' },
    { img: 'https://html.madrasthemes.com/bookworm-html/redesigned-octo-fiesta/assets/img/300x452/img1.jpg', name: 'think link a monk train Your Mind for peace and Purpose', author: 'Jay Shetty', price: '180' },
    { img: 'https://html.madrasthemes.com/bookworm-html/redesigned-octo-fiesta/assets/img/300x452/img1.jpg', name: 'think link a monk train Your Mind for peace and Purpose', author: 'Kelly Harms', price: '190' },

]


const author = [
    { name: 'Joe wick', profile: 'https://html.madrasthemes.com/bookworm-html/redesigned-octo-fiesta/assets/img/140x140/img4.jpg' },
    { name: 'Joe wick', profile: 'https://html.madrasthemes.com/bookworm-html/redesigned-octo-fiesta/assets/img/140x140/img4.jpg' },
    { name: 'Joe wick', profile: 'https://html.madrasthemes.com/bookworm-html/redesigned-octo-fiesta/assets/img/140x140/img4.jpg' },
    { name: 'Joe wick', profile: 'https://html.madrasthemes.com/bookworm-html/redesigned-octo-fiesta/assets/img/140x140/img4.jpg' },
    { name: 'Joe wick', profile: 'https://html.madrasthemes.com/bookworm-html/redesigned-octo-fiesta/assets/img/140x140/img4.jpg' }

]


const Home = () => {
    return (
        <Box sx={{ px: 10 }}>
            <section>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                    <Typography variant='h1'>Featured Categories</Typography>
                    <Button endIcon={<ChevronRightIcon />} >All Categories</Button>
                </Box>
                <Box>
                    <Grid2 container spacing={2}>
                        {categories.map((item, index) => (
                            <Grid2 item xs={12} sm={6} md={3} key={item.id}>
                                <Card sx={{ bgcolor: item.color, padding: 2, borderRadius: 0, width: '236px', height: '120px', cursor: 'pointer' }} elevation={0}>
                                    <Typography variant="h6">{item.name}</Typography>
                                    <Typography variant="h6">Shop Now</Typography>
                                </Card>
                            </Grid2>
                        ))}
                    </Grid2>
                </Box>
            </section>

            <section>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                    <Typography variant='h1'>Bestselling Books</Typography>
                    <Button endIcon={<ChevronRightIcon />} >View All</Button>
                </Box>
                <Box>
                    <Grid2 container>
                        {bestbooks.map((item, index) => (
                            <Grid2 item xs={12} sm={6} md={3} key={item.id}>
                                <Card sx={{ bgcolor: item.color, padding: 2, borderRadius: 0, width: '246px', cursor: 'pointer', border: 1, borderColor: '#ccc' }} elevation={0}>
                                    <Box sx={{ display: 'flex', justifyContent: "center", alignItems: 'center', pb: 2 }}>
                                        <img src={item.img} alt={item.name} style={{ width: '60%' }} />
                                    </Box>
                                    <Box sx={{ px: 2 }}>
                                        <Typography variant="body1" >{item.name}</Typography>
                                        <Typography variant="body2" gutterBottom>{item.author}</Typography>
                                        <Typography variant="h5">{item.price}</Typography>

                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
                                        <Button>Add to Cart</Button>
                                        <IconButton>
                                            <FavoriteBorderIcon />
                                        </IconButton>
                                    </Box>
                                </Card>
                            </Grid2>
                        ))}
                    </Grid2>
                </Box>
            </section >


            <section>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                    <Typography variant='h1'>Favorite Authors</Typography>
                    <Button endIcon={<ChevronRightIcon />} >View All</Button>
                </Box>

                <Box>
                    <Grid2 container spacing={2}>
                        {author.map((item, index) => (
                            <Grid2 item xs={12} sm={6} md={3} key={item.id}>
                                <Card sx={{ bgcolor: item.color, padding: 2, borderRadius: 0, width: '175px', cursor: 'pointer', flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center' }} elevation={0}>
                                    <img src={item.profile} alt={item.name} style={{
                                        width: '65%',
                                        borderRadius: '50%', marginBottom: 10
                                    }} />
                                    <Typography variant="h6">{item.name}</Typography>
                                    <Typography >34 published books</Typography>

                                </Card>
                            </Grid2>
                        ))}
                    </Grid2>
                </Box>
            </section >
        </Box >
    );
};

export default Home;
