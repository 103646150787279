import React, { useMemo, useState } from 'react';
import {
    Box, Typography,
    IconButton, Tooltip,
    CircularProgress
} from '@mui/material';
import CustomTable from '../../components/CustomTable';
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import { fetchBook, deleteBook } from '../../api';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import EastIcon from '@mui/icons-material/East';
import { useSnackbar } from 'notistack';
import DeleteModal from '../../components/DeleteModal';


const AllBooks = () => {


    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteItem, setDeleteItem] = useState(null);


    const { data: bookData, isLoading, isError } = useQuery('books', fetchBook);
    const data = useMemo(() => bookData || [], [bookData]);


    const deleteMutation = useMutation(deleteBook, {
        onSuccess: () => {
            queryClient.invalidateQueries('books');
            enqueueSnackbar('Book deleted successfully', { variant: 'success' });
        },
        onError: (error) => {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    })



    const handleDelete = (item) => {
        setDeleteItem(item);
        setDeleteModalOpen(true);
    };

    const confirmDelete = () => {
        if (deleteItem) {
            deleteMutation.mutate(deleteItem._id)
        }
        setDeleteModalOpen(false)
    };

    const cancelDelete = () => {
        setDeleteModalOpen(false);
    };


    // Define columns
    const columns = [
        { header: "Sr.No", accessorFn: (row, index) => index + 1, grow: false, size: 50 },
        { header: "ISBN", accessorKey: "ISBN" },
        { header: "Name", accessorKey: "title" },
        { header: "Author", accessorKey: "author" },
        { header: "Quantity", accessorKey: "stockQuantity" },
        { header: "Price", accessorKey: "price" },
        {
            header: "Actions",
            accessorFn: (row) => row,
            Cell: ({ cell }) => (
                <Box>
                    <Tooltip title="Edit">
                        <IconButton color='primary'>
                            <ModeEditOutlineRoundedIcon fontSize='small' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <IconButton color='error' onClick={() => handleDelete(cell.getValue())}>
                            <DeleteIcon fontSize='small' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Details">
                        <IconButton color='secondary'>
                            <EastIcon fontSize='small' />
                        </IconButton>
                    </Tooltip>
                </Box>
            ),
        },
    ];

    // Display loading state
    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    // Display error state
    if (isError) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Typography variant="h6" color="error">Failed to load books data</Typography>
            </Box>
        );
    }

    return (
        <>
            <Box sx={{ p: 2 }}>
                <Typography variant="h6" sx={{ fontSize: 18, fontWeight: 450, mb: 2 }}>
                    All Books
                </Typography>
                <CustomTable
                    data={data}
                    columns={columns}
                    AddComponent={<></>}
                />
            </Box>
            <DeleteModal
                open={deleteModalOpen}
                onClose={cancelDelete}
                onConfirm={confirmDelete}
                itemName={deleteItem ? deleteItem.title : ''}
            />
        </>

    );
};

export default AllBooks;
