import React from 'react';
import {
    Chip, Box, Tooltip, IconButton,
    Typography
} from '@mui/material';
import CustomTable from '../../components/CustomTable';
import EastIcon from '@mui/icons-material/East';

const AllOrder = () => {
    // Static data for orders
    const orders = [
        { id: 1, customerName: 'John Doe', date: '2024-09-20', status: 'Shipped', total: '$150' },
        { id: 2, customerName: 'Jane Smith', date: '2024-09-18', status: 'Pending', total: '$200' },
        { id: 3, customerName: 'Alice Johnson', date: '2024-09-15', status: 'Delivered', total: '$320' },
        { id: 4, customerName: 'Bob Brown', date: '2024-09-12', status: 'Cancelled', total: '$90' },
    ];


    const columns = [
        { header: "Sr.No", accessorKey: "id" },
        { header: "Customer Name", accessorKey: "customerName" },
        { header: "Date", accessorKey: "date" },
        {
            header: "Status",
            accessorKey: "status",
            Cell: ({ cell }) => (
                <Chip label={cell.getValue()} color={getStatusColor(cell.getValue())} />
            )
        },
        { header: "Total", accessorKey: "total" },
        {
            header: "Actions",
            accessorFn: (row) => row,
            Cell: ({ cell }) => (
                <Box>
                    <Tooltip title="Details">
                        <IconButton color='secondary'>
                            <EastIcon fontSize='small' />
                        </IconButton>
                    </Tooltip>
                </Box>
            ),
        },
    ]

    const getStatusColor = (status) => {
        switch (status) {
            case 'Shipped':
                return 'primary';
            case 'Pending':
                return 'warning';
            case 'Delivered':
                return 'success';
            case 'Cancelled':
                return 'error';
            default:
                return 'default';
        }
    };

    return (

        <Box sx={{ px: 2, pt: 1 }}>
            <Typography variant="h6" sx={{ fontSize: 18, fontWeight: 450, mb: 2 }}>Orders</Typography>
            <CustomTable
                data={orders}
                columns={columns}
                AddComponent={<></>}
            />
        </Box >


    );
};

export default AllOrder;
