import React from 'react'
import { Routes, Route, useLocation } from "react-router-dom"
import Footer from './components/Footer'
import Authors from './pages/Authors'
import ErrorPage from './components/ErrorPage'
import About from './pages/About'
import Term from './components/Term'
import Faq from './components/Faq'
import Shop from './pages/Shop'
import Cart from './pages/Cart'
import Checkout from './pages/Checkout'
import OrderTrack from './pages/OrderTrack'
import Account from './pages/Account'
import Received from './pages/Received'
import Books from './pages/Books'
import BookDetails from './pages/BookDetails'
import Home from './pages/Home'
import Contact from './pages/Contact'
import Header from './components/Header'
import Login from './auth/Login'
import SignUp from './auth/SignUp'
import Users from './pages/admin/Users'
import Setting from './pages/admin/Setting'
import AllBooks from './pages/admin/AllBooks'
import Dashboard from './pages/admin/Dashboard'
import AddBooks from './pages/admin/AddBooks'
import AllOrder from './pages/admin/AllOrder'
import Stores from './pages/Stores'
import OrderTracking from './pages/OrderTracking'
import TermConditons from './components/TermConditons'
import ComingSoon from './components/ComingSoon'




const ROUTES = [
  { path: '/', element: <Home /> },
  { path: '/about', element: <About /> },


]

const ADMIN_ROUTES = [
  { path: '/admin/dashboard', element: <Dashboard /> },
  { path: '/admin/books', element: <AllBooks /> },
  { path: '/admin/addbook', element: <AddBooks /> },
  { path: '/admin/allorders', element: <AllOrder /> },
  { path: '/admin/users', element: <Users /> },
  { path: '/admin/setting', element: <Setting /> },

]



const App = () => {
  const { pathname } = useLocation();
  const isCustomerRoutes = ROUTES.some(route => route.path === pathname);
  const isLoginOrSignupRoutes = ['/login', "/signup"].includes(pathname);
  const isPageNotFound = !ROUTES.some(route => route.path === pathname) &&
    !ADMIN_ROUTES.some(route => route.path === pathname) &&
    !isLoginOrSignupRoutes;

  return (
    <>
      {/* {!isLoginOrSignupRoutes && !isPageNotFound && < Header />}
      <Routes>
        {[...ROUTES, ...ADMIN_ROUTES].map((item, index) => (
          <Route
            exact
            key={index}
            path={item.path}
            element={item.element}
          />
        ))}
        <Route path='*' element={<ErrorPage />} />
        <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<SignUp />} />

      </Routes>
      {isCustomerRoutes && !isPageNotFound && < Footer />} */}
      <ComingSoon />
    </>


  )
}

export default App