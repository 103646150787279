// import React, { useState } from 'react';
// import { Grid, TextField, Card, CardContent, Typography, Button, Box, Paper, Divider } from '@mui/material';
// import { useMutation, useQueryClient, useQuery } from 'react-query';
// import { createBook } from '../../api';
// import { useSnackbar } from "notistack"


// const AddBooks = () => {

//     const queryClient = useQueryClient();
//     const { enqueueSnackbar } = useSnackbar();


//     const [formData, setFormData] = useState({
//         title: '',
//         author: '',
//         description: '',
//         price: '',
//         category: '',
//         stockQuantity: '',
//         ISBN: '',
//         coverImage: '',
//         publisher: '',
//         publishDate: '',
//         language: '',
//         pages: ''
//     });


//     const addMutation = useMutation(createBook, {
//         onSuccess: () => {
//             queryClient.invalidateQueries('book');
//             enqueueSnackbar('New book added successfully', { variant: 'success' });
//         },
//         onError: (error) => {
//             enqueueSnackbar(error.message, { variant: 'error' });
//         }
//     })

//     const handleChange = (event) => {
//         const { name, value } = event.target;
//         setFormData((prevData) => ({
//             ...prevData,
//             [name]: value
//         }))
//     }

//     const handleSave = () => {
//         addMutation.mutate(formData)
//     }



//     return (

//         <>
//             <Box sx={{ p: 2 }}>
//                 <Typography variant='h6' fontSize={18} fontWeight={450}>Add Books</Typography>

//                 <Grid container spacing={2}>
//                     <Grid item xs={9}>
//                         <Paper elevation={0} sx={{ border: "1px solid #ccc", p: 2 }}>
//                             <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
//                                 <TextField
//                                     label="Title"
//                                     name="title"
//                                     size="small"
//                                     fullWidth
//                                     required
//                                     value={formData.title}
//                                     onChange={handleChange}
//                                 />
//                                 <TextField
//                                     label="Author"
//                                     name="author"
//                                     size="small"
//                                     fullWidth
//                                     required
//                                     value={formData.author}
//                                     onChange={handleChange}
//                                 />
//                             </Box>
//                             <Box sx={{ mb: 2 }}>
//                                 <TextField
//                                     label="Description"
//                                     name="description"
//                                     size="small"
//                                     fullWidth
//                                     required
//                                     multiline
//                                     rows={5}
//                                     value={formData.description}
//                                     onChange={handleChange}
//                                 />
//                             </Box>
//                             <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
//                                 <TextField
//                                     label="ISBN"
//                                     name="ISBN"
//                                     size="small"
//                                     fullWidth
//                                     required
//                                     value={formData.ISBN}
//                                     onChange={handleChange}
//                                 />

//                                 <TextField
//                                     label="Price"
//                                     name="price"
//                                     type="number"
//                                     size="small"
//                                     fullWidth
//                                     required
//                                     value={formData.price}
//                                     onChange={handleChange}
//                                 />

//                                 <TextField
//                                     label="Stock Quantity"
//                                     name="stockQuantity"
//                                     type="number"
//                                     size="small"
//                                     fullWidth
//                                     required
//                                     value={formData.stockQuantity}
//                                     onChange={handleChange}
//                                 />
//                                 <TextField
//                                     label="Category"
//                                     name="category"
//                                     size="small"
//                                     fullWidth
//                                     value={formData.category}
//                                     onChange={handleChange}
//                                 />

//                             </Box>
//                             <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>

//                                 <TextField
//                                     label="Publisher"
//                                     name="publisher"
//                                     size="small"
//                                     fullWidth
//                                     value={formData.publisher}
//                                     onChange={handleChange}
//                                 />
//                                 <TextField
//                                     label="Language"
//                                     name="language"
//                                     size="small"
//                                     fullWidth
//                                     value={formData.language}
//                                     onChange={handleChange}
//                                 />
//                                 <TextField
//                                     label="Pages"
//                                     name="pages"
//                                     size="small"
//                                     type="number"
//                                     fullWidth
//                                     value={formData.pages}
//                                     onChange={handleChange}
//                                 />
//                             </Box>
//                             <Divider sx={{ my: 3 }} />
//                             <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 1 }}>
//                                 <Button
//                                     variant="contained"
//                                     color="primary"
//                                     onClick={handleSave}
//                                 >
//                                     publish
//                                 </Button>
//                                 <Button variant="outlined" color="error" >
//                                     cancel
//                                 </Button>

//                             </Box>

//                         </Paper>
//                     </Grid>
//                     <Grid item xs={3}>
//                         <Paper elevation={0} sx={{ border: "1px solid #ccc", p: 1 }}>
//                             Mahajan
//                         </Paper>
//                     </Grid>
//                 </Grid>
//             </Box >
//         </>

//     );
// };

// export default AddBooks;





import React, { useState } from 'react';
import { Grid, TextField, Card, CardContent, Typography, Button, Box, Paper, Divider } from '@mui/material';
import { useMutation, useQueryClient } from 'react-query'; // Removed useQuery since it's not being used
import { createBook } from '../../api';
import { useSnackbar } from "notistack";

const AddBooks = () => {

    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    const [formData, setFormData] = useState({
        title: '',
        author: '',
        description: '',
        price: '',
        category: '',
        stockQuantity: '',
        ISBN: '',
        coverImage: '', // You have this field in state, but it's not used in the form. Consider adding an input for cover image.
        publisher: '',
        publishDate: '', // You might want to add a date picker for publish date
        language: '',
        pages: ''
    });

    const addMutation = useMutation(createBook, {
        onSuccess: () => {
            queryClient.invalidateQueries('book');
            enqueueSnackbar('New book added successfully', { variant: 'success' });
        },
        onError: (error) => {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSave = () => {
        // Validation can be added here before calling the mutation
        if (formData.title === '' || formData.author === '' || formData.ISBN === '') {
            enqueueSnackbar('Please fill all required fields', { variant: 'warning' });
            return;
        }
        addMutation.mutate(formData); // Check that coverImage handling is done if necessary
    };

    return (
        <>
            <Box sx={{ p: 2 }}>
                <Typography variant='h6' fontSize={18} fontWeight={450}>Add Books</Typography>

                <Grid container spacing={2}>
                    <Grid item xs={9}>
                        <Paper elevation={0} sx={{ border: "1px solid #ccc", p: 2 }}>
                            <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
                                <TextField
                                    label="Title"
                                    name="title"
                                    size="small"
                                    fullWidth
                                    required
                                    value={formData.title}
                                    onChange={handleChange}
                                />
                                <TextField
                                    label="Author"
                                    name="author"
                                    size="small"
                                    fullWidth
                                    required
                                    value={formData.author}
                                    onChange={handleChange}
                                />
                            </Box>
                            <Box sx={{ mb: 2 }}>
                                <TextField
                                    label="Description"
                                    name="description"
                                    size="small"
                                    fullWidth
                                    required
                                    multiline
                                    rows={5}
                                    value={formData.description}
                                    onChange={handleChange}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
                                <TextField
                                    label="ISBN"
                                    name="ISBN"
                                    size="small"
                                    fullWidth
                                    required
                                    value={formData.ISBN}
                                    onChange={handleChange}
                                />
                                <TextField
                                    label="Price"
                                    name="price"
                                    type="number"
                                    size="small"
                                    fullWidth
                                    required
                                    value={formData.price}
                                    onChange={handleChange}
                                />
                                <TextField
                                    label="Stock Quantity"
                                    name="stockQuantity"
                                    type="number"
                                    size="small"
                                    fullWidth
                                    required
                                    value={formData.stockQuantity}
                                    onChange={handleChange}
                                />
                                <TextField
                                    label="Category"
                                    name="category"
                                    size="small"
                                    fullWidth
                                    value={formData.category}
                                    onChange={handleChange}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
                                <TextField
                                    label="Publisher"
                                    name="publisher"
                                    size="small"
                                    fullWidth
                                    value={formData.publisher}
                                    onChange={handleChange}
                                />
                                <TextField
                                    label="Language"
                                    name="language"
                                    size="small"
                                    fullWidth
                                    value={formData.language}
                                    onChange={handleChange}
                                />
                                <TextField
                                    label="Pages"
                                    name="pages"
                                    size="small"
                                    type="number"
                                    fullWidth
                                    value={formData.pages}
                                    onChange={handleChange}
                                />
                            </Box>
                            <Divider sx={{ my: 3 }} />
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 1 }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSave}
                                >
                                    Publish
                                </Button>
                                <Button variant="outlined" color="error">
                                    Cancel
                                </Button>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={3}>
                        <Paper elevation={0} sx={{ border: "1px solid #ccc", p: 1 }}>
                            {/* You might want to add a book preview section here */}
                            <Typography variant="subtitle1">Book Preview</Typography>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h6">{formData.title}</Typography>
                                    <Typography variant="body2">{formData.author}</Typography>
                                    <Typography variant="body2">{formData.description}</Typography>
                                    <Typography variant="body2">Price: {formData.price}</Typography>
                                    <Typography variant="body2">ISBN: {formData.ISBN}</Typography>
                                </CardContent>
                            </Card>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default AddBooks;
