import React from 'react';
import { Grid, Card, CardContent, Typography, Box } from '@mui/material';

const Dashboard = () => {
    // Dummy data for the dashboard
    const totalCustomers = 120;
    const totalBooks = 450;
    const totalOrders = 320;
    const booksLeft = 130;
    const revenue = '$15,000';
    const orders = [
        { id: 1, customer: 'John Doe', book: 'React for Beginners', amount: '$30' },
        { id: 2, customer: 'Jane Smith', book: 'JavaScript Mastery', amount: '$45' },
        { id: 3, customer: 'Michael Johnson', book: 'Advanced Node.js', amount: '$50' }
    ];

    return (
        <Box sx={{ p: 2 }}>
            <Typography variant="h6" fontSize={20} fontWeight={450} >
                Hi Welcome, Harshal Mahajan
            </Typography>

            {/* First Row: Summary Cards */}
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Customers</Typography>
                            <Typography variant="h4">{totalCustomers}</Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Books</Typography>
                            <Typography variant="h4">{totalBooks}</Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Orders</Typography>
                            <Typography variant="h4">{totalOrders}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <Card sx={{ borderWidth: "1px", borderColor: 'divider' }} elevation={0}>
                        <CardContent>
                            <Typography variant="h6">Revenue</Typography>
                            <Typography variant="h4">{revenue}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {/* Second Row: Orders Table */}
            <Typography variant="h5" gutterBottom style={{ marginTop: '2rem' }}>
                Recent Orders
            </Typography>

            <Grid container spacing={3}>
                {orders.map(order => (
                    <Grid item xs={12} sm={6} md={4} key={order.id}>
                        <Card>
                            <CardContent>
                                <Typography variant="subtitle1">Customer: {order.customer}</Typography>
                                <Typography variant="subtitle2">Book: {order.book}</Typography>
                                <Typography variant="subtitle2">Amount: {order.amount}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default Dashboard;


